jQuery(document).ready(function () {
  // athome();
  accordionWidget();
  playYT();
  playYTM();
  tableCollapse();
  // summerEvent();
  onScrollHeader();
  toggleSwitch();
  toggleServer();
  priceTableCollapse();
  prependNew();
});

function prependNew() {
  $("<span class='new_lbl'>NEW</span>").insertBefore(
    ".low-prices .avia-menu-text"
  );
}

function athome() {
  setTimeout(function () {
    $(".at-home").removeClass("hide");
    $(".at-home").addClass("show-div");
  }, 3000);

  $(".at-home .close-btn-pop").click(function () {
    $(".at-home").removeClass("show-div");
    $(".at-home").addClass("hide");
  });
}

function onScrollHeader() {
  $(window).scroll(function () {
    if ($(window).scrollTop() == 0) {
      $(".header_bg").attr("style", "background-color:transparent !important");
    } else {
      $(".header_bg").attr("style", "background-color:#fff !important");
    }
  });
}

function summerEvent() {
  var first = window.location.href;

  if (first == "http://xesktop.local" || first == "http://xesktop.local/") {
    setTimeout(function () {
      $(".summer-sale-mobile").removeClass("hide");
      $(".summer-sale").removeClass("hide");
      $(".summer-sale").addClass("show-div");
    }, 3000);
  }

  $(".summer-sale .close-btn-pop").click(function () {
    $(".summer-sale").removeClass("show-div");
    $(".summer-sale").addClass("hide");
  });

  $(".summer-btn").click(function () {
    clicked = true;

    if (clicked == true) {
      $(".summer-btn").addClass("hide");
      $(".summer-btn-get").removeClass("hide");
    }

    $(".summer-sale").css("width", "450px");
    $(".summer-right").css("float", "right");

    setTimeout(function () {
      $(".summer-right").animate({ width: "49%" }, 100);

      setTimeout(function () {
        $(".summer-left").removeClass("hide");
      }, 1000);
    }, 500);
    return false;
  });
}

function tableCollapse() {
  jQuery.fn.extend({
    toggleText: function (a, b) {
      var that = this;
      if (that.html() != a && that.html() != b) {
        that.html(a);
      } else if (that.html() == a) {
        that.html(b);
      } else if (that.html() == b) {
        that.html(a);
      }
      return this;
    },
  });
  $(".show-more").click(function () {
    $(".collapsex").toggle("slow");
    $(".show-more").toggleText(
      '<i class="fa fa-angle-down" aria-hidden="true"></i> Hide',
      '<i class="fa fa-angle-up" aria-hidden="true"></i> Show more'
    );
  });
}

function accordionWidget() {
  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].onclick = function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    };
  }
}

function playYTM() {
  $(".mobile-play").click(function () {
    player = new YT.Player("player-mobile", {
      width: "100%",
      height: "360",
      videoId: "tAYMWRQ3_Ys",
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });
  });

  var tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  var player;
  function onPlayerReady(event) {
    event.target.playVideo();
  }
  function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.ENDED) {
      // player.destroy();
    }
  }
}

function playYT() {
  $(".click-to-play-video").click(function () {
    player = new YT.Player("player", {
      width: "1141",
      height: "641",
      videoId: "tAYMWRQ3_Ys",
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });
  });

  var tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  var player;
  function onPlayerReady(event) {
    event.target.playVideo();
  }
  function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.ENDED) {
      // player.destroy();
    }
  }
}

function toggleSwitch() {
  $('.switch input[type="checkbox"]').click(function () {
    1 == $(this).prop("checked")
      ? ($(".hour p").addClass("hour-toggled"),
        $(".week p").addClass("week-toggled"),
        $(".slider").addClass("toggled"),
        $(".server-container_box--price").hide(),
        $(".server-container_box--price__toggled").show(),
        $(".percent").show())
      : 0 == $(this).prop("checked") &&
        ($(".hour p").removeClass("hour-toggled"),
        $(".week p").removeClass("week-toggled"),
        $(".slider").removeClass("toggled"),
        $(".server-container_box--price").show(),
        $(".server-container_box--price__toggled").hide(),
        $(".percent").hide());
  });
}

function toggleServer() {
  $(".val1").click(function () {
    $(".server2").hide(),
      $(".server3").hide(),
      $(".val1").addClass("val-toggled"),
      $(".val2").removeClass("val-toggled"),
      $(".val3").removeClass("val-toggled"),
      $(".server1").show();
  });
  $(".val2").click(function () {
    $(".server1").hide(),
      $(".server3").hide(),
      $(".val2").addClass("val-toggled"),
      $(".val1").removeClass("val-toggled"),
      $(".val3").removeClass("val-toggled"),
      $(".server2").show();
  });
  $(".val3").click(function () {
    $(".server1").hide(),
      $(".server2").hide(),
      $(".val3").addClass("val-toggled"),
      $(".val1").removeClass("val-toggled"),
      $(".val2").removeClass("val-toggled"),
      $(".server3").show();
  });
}

function priceTableCollapse() {
  var btn = $(".table-wrapper_toggle p");
  var target = $("#scrolled");
  jQuery.fn.extend({
    toggleText: function (a, b) {
      var that = this;
      if (that.html() != a && that.html() != b) {
        that.html(a);
      } else if (that.html() == a) {
        that.html(b);
      } else if (that.html() == b) {
        that.html(a);
      }
      return this;
    },
  });
  $(btn).click(function () {
    $(".collapse").toggle("slow");
    $(btn).toggleText("hide", "show more");
    $("html, body").animate(
      {
        scrollTop: $(target).offset().top,
      },
      1000
    );
    [[]];
  });
}
